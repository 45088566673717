import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Layout from '@components/layout/Layout'
import EmailFinderComponent from '@components/locate-sleep-specialist/email-finder/email-finder.component'
import SearchBoxContainer from '@components/locate-sleep-specialist/search-box/search-box.component'
import ReadNext from '@components/read-next-button/read-next-button'

import './locate-sleep-specialist.scss'

class LocateSleepSpecialist extends Component {
  constructor(props) {
    super(props)
    this.state = {
      search: false,
      style: {
        height: '0',
        width: '0',
      },
    }
  }

  render() {
    // For SEO-redirect requirements.
    if (this.props.location.hash === '#professionals/join') {
      return <Redirect to="/stay-connected/join-the-sleep-specialist-directory/" />
    }

    const readNext = {
      readNextContent: 'Learn more about NarcolepsyLink',
      goTo: '/stay-connected/about-narcolepsy-link/',
      tracking: 'find sleep specialist, click,	what causes narcolepsy - find sleep specialist'
    }

    return (
      <Layout addedClass="page-find-sleep-specialist" location={this.props.location}>
        <div className="main">
          <div className="search-section">
            <div className="sleep-specialist-header">
              <h1 className="main-page-header">Find a Sleep Specialist in 3 Simple Steps</h1>
            </div>
            <ol className="search-steps">
              <li className="list-item-objects">
                <div className="list-number">1</div>
                <div>Enter your patient’s location to find nearby sleep specialists</div>
              </li>
              <li className="list-item-objects">
                <div className="list-number">2</div>
                <div>Select a specialist from the results</div>
              </li>
              <li className="list-item-objects">
                <div className="list-number">3</div>
                <div>Provide information for your patient to schedule an appointment</div>
              </li>
            </ol>
            <div className="search-wrapper">
              <div className="search-input-section">
                <SearchBoxContainer runMapSearch={this.mapSearch} />
                <label>United States Only</label>
              </div>
            </div>
          </div>
          <div className="sleep-specialist-stats">
            <div className="image-wrapper" role="image" aria-label="Narcolepsy Link sleep specialist database" alt="HCP at computer">
              <h3>Over 3000 Sleep Specialists</h3>
            </div>
          </div>
        </div>
        <EmailFinderComponent />
        <div className="primary-container">
          <ReadNext readNext={readNext} />
        </div>
      </Layout>
    )
  }
}

export default LocateSleepSpecialist

LocateSleepSpecialist.propTypes = {
  location: PropTypes.object,
}
