import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { trackGa } from '@components/GoogleTracking';
import { win, gmapsLoaded } from '@src/app-utils';
import { navigate } from "gatsby"

import './search-box.component.scss';

export class SearchBox extends Component {
	constructor() {
		super();

		this.state = {
			searchValid: false,
		};

		this.zipcodeInputRef = React.createRef();
    this.initSearchMap = this.initSearchMap.bind(this)
	}

	initSearchMap() {
		if (win && gmapsLoaded()) {
      console.log('gmap loaded');
			let input = this.zipcodeInputRef.current;
			let options = {
				types: ['(regions)'],
				componentRestrictions: { country: 'us' },
			};
			let autocomplete = new window.google.maps.places.Autocomplete(input, options);

			new window.google.maps.event.addListener(
				autocomplete,
				'place_changed',
				() => {
					var place = autocomplete.getPlace();

					if (place.geometry) {
						localStorage.setItem('placeName', place.name);
						this.setCoordinates(place.geometry.location, place.name);
					} else {
						this.selectFirstResult();
					}
				}
			);
		}
	}

	componentDidMount() {
		// the autocomplete resutls persist when coming back and forth from the page so they need to be cleared when returning
		//so when a user hits enter, it selects the correct top result
		let autocompleteResults = document.getElementsByClassName('pac-container');
		if (autocompleteResults.length > 0) {
			for (let result of autocompleteResults) {
				result.innerHTML = '';
			}
		}

    if (win && gmapsLoaded()) {
      this.initSearchMap()
    } else {
      window.initMap = this.initSearchMap
    }
	}

	selectFirstResult() {
		let autocompleteResults = document.getElementsByClassName('pac-item');
		if (!autocompleteResults.length > 0) {
			return;
		}

		let firstResult = autocompleteResults[0].textContent;
		var geocoder = new window.google.maps.Geocoder();
		geocoder.geocode({ address: firstResult }, (results, status) => {
			if (status == window.google.maps.GeocoderStatus.OK) {
				localStorage.setItem(
					'placeName',
					results[0].address_components[0].long_name
				);
				this.setCoordinates(results[0].geometry.location);
			}
		});
	}

	setCoordinates(location, placeName) {
		this.setState({
			searchValid: true,
		});

		let bounds = new window.google.maps.LatLngBounds();
		bounds.extend(location);
		let coordinatesString = JSON.stringify(bounds);
		let coordinatesObject = JSON.parse(coordinatesString);

		//necessary for taking the returned bounds and turning them into a readable, consistent object
		localStorage.setItem('bounds', coordinatesString);
		localStorage.setItem(
			'lng',
			(coordinatesObject.east + coordinatesObject.west) / 2
		);
		localStorage.setItem(
			'lat',
			(coordinatesObject.north + coordinatesObject.south) / 2
		);
		trackGa(`find sleep specialist, zipcode or citystate, ${placeName}`);
		navigate('/stay-connected/physician-results/');
	}

	render() {
		return (
			<div className='search-component-wrapper'>
				<div
					className='search-input'
					id='pac-container'
					style={{ height: '60px' }}>
					<input
						id='pac-input'
						ref={this.zipcodeInputRef}
						type='text'
						placeholder='ZIP CODE OR CITY, STATE'
						style={{ height: '60px' }}
					/>
				</div>
				{this.state.searchValid ? (
					<div className='search-button-wrapper'>
						<button className='pp-search-button'>
							Search
							<span className='search-arrow'>
								<FontAwesomeIcon
									className='arrow-right'
									icon='angle-right'
								/>
							</span>
						</button>
					</div>
				) : (
					<div className='search-button-wrapper'>
						<button className='pp-search-button disabled'>
							Search
							<span className='search-arrow'>
								<FontAwesomeIcon
									className='arrow-right'
									icon='angle-right'
								/>
							</span>
						</button>
					</div>
				)}
			</div>
		);
	}
}

SearchBox.propTypes = {
	runMapSearch: PropTypes.func,
};

export default SearchBox;
